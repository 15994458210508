<template>
  <div>
    <div class="breadCurmb mt-3">
      <label>
        <router-link to="/driver/home">{{ $t("Area") }}</router-link>
      </label>
      <span>/</span>
      <label active>{{$route.name == 'sortEvents2'? 'ترتيب جميع المشتركين' : 'ترتيب المشتركين بالنسبة للحي'}}</label>
    </div>


    <h2 class="my-2">{{$route.name == 'sortEvents2'? 'ترتيب جميع المشتركين' : 'ترتيب المشتركين بالنسبة للحي'}}</h2>

    <!-- Start DataTable -->
    <v-data-table
      :headers="table.headers"
      :items="table.items"
      :loading="table.loading"
      hide-default-footer
      :items-per-page="table.items.length"
      class="elevation-1"
      :loading-text="$t('LoadData')"
    >
      <template v-slot:no-data>
        <v-alert class="mt-5 white-clr" color="third">
          {{ $t("NoData") }}
        </v-alert>
      </template>

      <template v-slot:item.customer_attachments="{ item }">
        <img
          @click="openGalary(item.customer_attachments)"
          v-if="item.customer_attachments"
          style="border-radius: 50%; margin: 0 5px; cursor: pointer"
          class="mr-2 mt-2"
          width="50"
          height="50"
          :src="
            item.customer_attachments.length > 0
              ? item.customer_attachments[item.customer_attachments.length - 1]
              : '/assets/no-img.png'
          "
          alt=""
        />
      </template>
      <template v-slot:item.branch="{ item }">
        <span>{{item.branch}} - {{item.company}}</span>
      </template>

      <template v-slot:item.driver_delivery="{ item, index }">
        <div>
          <span v-if="item.driver_delivery == 0">
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: purple !important"
              dense
              >mdi-circle-outline</v-icon
            >
          </span>
          <span v-if="item.driver_delivery == 1" class="checkbox-marked">
            <v-icon class="my-0 mr-2 ml-2" style="font-size: 28px" dense
              >mdi-checkbox-marked-circle-outline</v-icon
            >
          </span>
          <span v-if="item.driver_delivery == 2" class="checkbox-unmarked">
            <v-icon
              class="my-0 mr-2 ml-2"
              style="font-size: 28px; color: red"
              dense
              >mdi-close-circle-outline</v-icon
            >
          </span>

            <el-button
            v-if="details"
             style="padding:2px;"
            :disabled="(!details.deliver_btn) || item.driver_delivery > 0"
            @click="
              detailsItem = item;
              delivarModal = true;
            "
            class="mx-2"
            type="success"
            >
            <v-icon class="my-0" style="font-size: 28px;color:#fff !important;" dense
              >mdi-checkbox-marked-circle-outline</v-icon
            >
            </el-button
          >

          <el-button
            style="padding:2px;"
            v-if="details"
            :disabled="(!details.deliver_btn) || item.driver_delivery > 0"
            @click="openDeleteDelivar(item)"
            class="mx-2"
            type="danger"
            >
            <v-icon
              class="my-0"
              style="font-size: 28px; color: #fff !important;"
              dense
              >mdi-close-circle-outline</v-icon
            >
            </el-button
          >


        </div>
      </template>


      
      <template v-slot:item.actions="{ item, index }">
        <v-row>
    
          <template v-if="$vuetify.breakpoint.mdAndUp">

          <v-col :cols="'12'">

            <v-text-field
              class="my-3 mx-1"
              style="width: auto; position: relative;display:inline-block;"
              :style="'width: 12rem'"
              type="number"
              name="sort"
              hide-details
              dense
              outlined
              v-model.number="item.order"
              @keydown.enter="sort(item)"
            ></v-text-field>

          <v-btn
            color="primary"
            small
            style="display:inline-block;min-width:70px;"
            class="body-2 font-weight-bold mx-2"
            @click.native="sort(item)"
            >{{ $t("Sort") }}</v-btn>

            <v-icon
              @click="sortUp(item, index)"
              class="my-0 mx-1"
              style="font-size: 28px; position: relative;top:0;"
              :style="
                index > 0?
              'color:blue !important;cursor: pointer;'
              :$vuetify.breakpoint.mdAndUp &&  index <= 0 ?
              'color:#ccc !important;cursor:default'
              :index > 0?
              'color:blue !important;cursor: pointer;' : 
              'color:#ccc !important;cursor:default;'
              "
              dense
              :disabled="index == 0"
              >mdi-arrow-up</v-icon
            >
             <v-icon
              @click="sortDown(item, index)"
              class="my-0 mx-1"
              style="display:inline-block;font-size: 28px; position: relative;top:0;"
              dense
              :style="
                index < table.items.length - 1?
              'color:blue !important;cursor: pointer;':
              index >= table.items.length - 1 ?
              'color:#ccc !important;cursor:default;'
              :index >= table.items.length - 1?
              'color:blue !important;cursor: pointer;' : 
              'color:#ccc !important;cursor:default;'
              "
              >mdi-arrow-down</v-icon
            >
               <v-icon
              @click="updateLink(item)"
              class="my-0 mx-1"
              style="
                display:inline-block;
                font-size: 28px;
                cursor: pointer;
                position: relative;
                color:purple !important;
                cursor: pointer;
                top:0;
              "
              dense
              color="purple"
              >mdi-pen</v-icon
            >

            <v-icon
                @click="goToLink(item)"
                style="font-size: 28px; cursor: pointer; position: relative;margin: 0 15px;"
                :style="
                  item.link_map
                    ? 'color:green !important;cursor: pointer;'
                    : 'color:red !important;cursor:default;'
                "
                dense
                color="black"
                >mdi-map-marker</v-icon
              >

          </v-col>

          </template>

          <template v-else> <!-- mobile -->

          <v-col :cols="'12'">

             <v-text-field
              class="my-3 mx-1"
              style="width: auto; position: relative;display:inline-block;"
              :style="'width: 10rem'"
              type="number"
              name="sort"
              hide-details
              dense
              outlined
              v-model.number="item.order"
              @keydown.enter="sort(item)"
            ></v-text-field>

          <v-btn
            color="primary"
            small
            style="display:inline-block;min-width:70px;"
            class="body-2 font-weight-bold mx-1"
            @click.native="sort(item)"
            >{{ $t("Sort") }}</v-btn>

          </v-col>
          
          <v-col :cols="'12'" style="margin-top:-10px;margin-bottom:10px;">
            <v-icon
              @click="sortUp(item, index)"
              class="my-0 mx-3"
              style="font-size: 28px; position: relative;"
              :style="
              index > 0?
              'color:blue !important;cursor: pointer;' : 
              'color:#ccc !important;cursor:default;'
              "
              dense
              :disabled="index == 0"
              >mdi-arrow-up</v-icon
            >

            <v-icon
              @click="sortDown(item, index)"
              class="my-0 mx-3"
              style="font-size: 28px; position: relative;
              display:inline-block;
              "
              dense
              :style="index < table.items.length - 1?
              'color:blue !important;cursor: pointer;' : 
              'color:#ccc !important;cursor:default;'
              "
              >mdi-arrow-down</v-icon
            >
             <v-icon
              @click="updateLink(item)"
              class="my-0 mx-3"
              style="
                display:inline-block;
                font-size: 28px;
                cursor: pointer;
                position: relative;
              "
              :style="
              item.link_map?
              'color:purple !important;cursor: pointer;' : 
              'color:purple !important;cursor:pointer;'
              "
              dense
              
              color="black"
              >mdi-pen</v-icon
            >

            <v-icon
                @click="goToLink(item)"
                class="my-0"
                style="
                  display: inline-block;
                   margin-right: 15px !important;
                 margin-left: 15px !important;
                  font-size: 28px;
                  cursor: pointer;
                  position: relative;
                "
                :style="
                  item.link_map
                    ? 'color:green !important;cursor: pointer;'
                    : 'color:red !important;cursor:default;'
                "
                dense
                color="black"
                >mdi-map-marker</v-icon
              >

          </v-col>



          </template>

          
        </v-row>
      </template>


        
      
    </v-data-table>
    <!-- End DataTable -->

    <!-- <div class="mt-5" v-if="showMap">
      <AddGoogleMap ref="AddGoogleMap" style="width: 100%" :markers="markers" />
    </div> -->

    <!-- Start Pagination -->
    <!-- <div class="text-center pt-2">
      <v-pagination
        v-if="table.length"
        total-visible="5"
        v-model="table.page"
        :length="table.length"
        @input="changePage(table.page)"
      ></v-pagination>
    </div> -->

    <!-- End Pagination -->

    <v-dialog v-model="delivarModal" width="30rem">
      <v-card>
        <v-toolbar style="background: green !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">هل أنت متأكد من عملية التسليم</h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="delivarModal = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="delivar(1)"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="notDelivarModal" width="30rem">
      <v-card>
        <v-toolbar style="background: red !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            {{ $t("Confirm") }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <h3 class="clr-primary">
            هل أنت متأكد من عملية إلغاء التسليم ؟
            <v-row class="my-2">
              <v-col
                class="py-0"
                :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'"
              >
                <div
                  :class="{
                    'has-error': errors.has(`addEditValidation.note_delivary`),
                  }"
                >
                  <v-textarea
                    rows="4"
                    placeholder="سبب عدم التسليم"
                    class="d-block my-2"
                    name="note_delivary"
                    data-vv-scope="addEditValidation"
                    v-validate="'required'"
                    :data-vv-as="'سبب عدم التسليم'"
                    hide-details
                    dense
                    outlined
                    v-model="note_delivary"
                  >
                  </v-textarea>
                </div>

                <div
                  class="help-block"
                  v-if="errors.has(`addEditValidation.note_delivary`)"
                >
                  {{ errors.first(`addEditValidation.note_delivary`) }}
                </div>
              </v-col>
            </v-row>
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="notDelivarModal = false"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="notDelivar(2)"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="linkMapModal" width="30rem">
      <v-card>
        <v-toolbar style="background: red !important" dense flat>
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            إضافة رابط موقع
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <v-row class="my-2">
            <v-col
              class="py-0"
              :cols="$vuetify.breakpoint.mdAndUp ? '12' : '12'"
            >
              <div
                :class="{
                  'has-error': errors.has(`addEditValidation.link_map`),
                }"
              >
                <v-text-field
                  placeholder="رابط الموقع"
                  class="d-block my-2"
                  name="link_map"
                  data-vv-scope="addEditValidation"
                  v-validate="'required'"
                  :data-vv-as="'رابط الموقع'"
                  hide-details
                  dense
                  outlined
                  style="direction:ltr;"
                  v-model="link_map"
                >
                </v-text-field>
              </div>

              <div
                class="help-block"
                v-if="errors.has(`addEditValidation.link_map`)"
              >
                {{ errors.first(`addEditValidation.link_map`) }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="danger"
            text
            small
            class="body-2 font-weight-bold"
            @click.native="closeLinkMap"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            color="success"
            class="body-2 font-weight-bold"
            outlined
            small
            @click.native="saveLinkMap"
          >
            {{ $t("Ok") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="galaryModal" width="60rem">
      <v-card>
        <v-toolbar
          style="background: brown !important; color: #000 !important"
          dense
          flat
        >
          <v-toolbar-title class="text-body-2 font-weight-bold white-clr">
            معرض الصور
          </v-toolbar-title>
          <span
            @click="
              galaryModal = false;
              galaryIndex = 0;
            "
            style="
              color: #fff;
              cursor: pointer;
              position: absolute;
              top: 5px;
              left: 10px;
              font-size: 22px;
            "
            >x</span
          >
        </v-toolbar>
        <v-card-text class="py-0 pa-4 pt-4 black--text">
          <img
            style="width: 90%; height: 300px; display: block; margin: 0 auto"
            :src="galaryImages[galaryIndex]"
            alt=""
          />
        </v-card-text>
        <v-card-actions>
          <div style="display: block; margin: 0 auto">
            <v-btn
              v-if="galaryIndex < galaryImages.length - 1"
              small
              style="background: #000 !important"
              class="body-2 font-weight-bold mx-2"
              @click.native="galaryIndex = galaryIndex + 1"
            >
              <v-icon
                class="my-0 mr-2 mx-2"
                style="font-size: 28px; color: #fff;"
                dense
                >mdi-arrow-right</v-icon
              >
            </v-btn>
            <v-btn
              v-if="galaryIndex > 0"
              small
              style="background: #000 !important"
              class="body-2 font-weight-bold"
              @click.native="galaryIndex = galaryIndex - 1"
            >
              <v-icon
                class="my-0 mr-2 ml-2"
                style="font-size: 28px; color: #fff"
                dense
                >mdi-arrow-left</v-icon
              >
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <BlockUI v-if="loadClicked" :message="msg">
      <div class="sk-wave">
        <div class="sk-rect sk-rect1"></div>
        <div class="sk-rect sk-rect2"></div>
        <div class="sk-rect sk-rect3"></div>
        <div class="sk-rect sk-rect4"></div>
        <div class="sk-rect sk-rect5"></div>
      </div>
    </BlockUI>
  </div>
</template>




<script>
import moment from "moment";
// import AddGoogleMap from "./AddGoogleMap.vue";
export default {
  name: "eventsIndex",
  components: {
    // AddGoogleMap,
  },
  data() {
    return {
      linkMapModal: false,
      link_map: null,
      note_delivary: null,
      detailsItem: null,
      delivarModal: false,
      notDelivarModal: false,
      loadClicked: false,
      msg: "برجاء الانتظار لحين اكتمال العملية",
      filters: {
        neighborhood_id: null,
      },
      branchesList: [],
      details: null,
      markers: [],
      showMap: false,
      filtersItems: [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: 1 },
        { text: this.$t("InActive"), value: 0 },
      ],
      config: {
        crudApi: "eventSubscribtion",
        filterWidth: "20rem",
        deleteModalWidth: "30rem",
        modalFormWidth: "50rem",
      },
      table: {
        page: 1,
        length: null,
        itemsPerPage: 10,
        loading: false,
        items: [],
        itemExport: {
          "#": "id",
          "الاسم عربي": {
            field: "name.ar",
            callback: (item) => {
              if (item) return item;
            },
          },
          "الاسم إنجليزي": {
            field: "name.en",
            callback: (item) => {
              if (item) return item;
            },
          },
          الحالة: {
            field: "status",
            callback: (value) => {
              if (value) return "فعال";
              else return "غير فعال";
            },
          },
        },
        headers: [
          { text: "#", value: "id" },
          {
            text: this.$t("Name"),
            value: "customer_name",
          },
           {
            text: "المنطقة - الشركة",
            value: "branch",
          },
          {
            text: this.$t("Actions"),
            value: "actions",
            sortable: false,
            align: "center",
          },
        ],
      },
      filters: {
        name: null,
        status: null,
      },
      formData: {
        id: 0,
        sort: null,
      },
      drawer: false,
      dialog: false,
      dialogDelete: false,
      galaryImages: [],
      galaryIndex: 0,
      galaryModal: false,
      itemLinkMap: null,
    };
  },
  methods: {
    closeLinkMap() {
      this.linkMapModal= false;
    },
    saveLinkMap(item) {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");

        let sendData = {
          id: this.itemLinkMap.subscription_id,
          link_map: this.link_map,
        };
        this.$store
          .dispatch(`driverDetails/uploadLinkMap`, sendData)
          .then((res) => {
            this.notifySuccess("File", res);
            this.linkMapModal = false;
            if (this.$route.name == "sortEvents") {
              this.getDataFromApi();
            } else if (this.$route.name == "sortEvents2") {
              this.getDataFromApi2();
            }
          });
      });
    },
    openDeleteDelivar(item) {
      this.note_delivary = null;
      this.$validator.reset();
      this.detailsItem = item;
      this.notDelivarModal = true;
    },
    openGalary(images) {
      if (images.length > 0) {
        this.galaryImages = images;
        this.galaryIndex = 0;
        this.galaryModal = true;
      }
    },
    uploadImage(files, id) {
      this.tobase64Handler(files, id);
    },

    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async tobase64Handler(files, id) {
      const filePathsPromises = [];
      files.forEach((file) => {
        filePathsPromises.push(this.toBase64(file));
      });
      const filePaths = await Promise.all(filePathsPromises);
      const mappedFiles = filePaths.map((base64File) => base64File);

      let sendData = {
        files: mappedFiles,
        id: id,
      };

      this.$store
        .dispatch(`driverDetails/uploadImages`, sendData)
        .then((res) => {
          this.notifySuccess("Upload", "Upload Successfully");
          if (this.$route.name == "sortEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "sortEvents2") {
            this.getDataFromApi2();
          }
        });
    },
    //    handleFileSelect(files,id) {
    //     let images= [];
    //     files.forEach(f => {
    //       const toBase64 = file => new Promise((resolve, reject) => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(file);
    //       reader.onload = () => resolve(reader.result);
    //       reader.onerror = error => reject(error);
    //       });
    //     });

    //     console.log(images);

    //  },
    notDelivar(val) {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        let sendData = {
          subscription_detail_id: this.detailsItem.subscription_detail_id,
          driver_delivery: val,
          note_delivary: this.note_delivary,
        };
        this.$store
          .dispatch(`driverDetails/update_driver_delivery`, sendData)
          .then((res) => {
            this.notifySuccess(`UpdateStatus`, res);
            if (this.delivarModal) this.delivarModal = false;
            if (this.notDelivarModal) this.notDelivarModal = false;

            if (this.$route.name == "sortEvents") {
              this.detailsItem = null;
              this.getDataFromApi();
            } else if (this.$route.name == "sortEvents2") {
              this.detailsItem = null;
              this.getDataFromApi2();
            }
          });
      });
    },

    delivar(val) {
      let sendData = {
        subscription_detail_id: this.detailsItem.subscription_detail_id,
        driver_delivery: val,
      };
      this.$store
        .dispatch(`driverDetails/update_driver_delivery`, sendData)
        .then((res) => {
          this.notifySuccess(`UpdateStatus`, res);
          if (this.delivarModal) this.delivarModal = false;
          if (this.notDelivarModal) this.notDelivarModal = false;

          if (this.$route.name == "sortEvents") {
            this.detailsItem = null;
            this.getDataFromApi();
          } else if (this.$route.name == "sortEvents2") {
            this.detailsItem = null;
            this.getDataFromApi2();
          }
        });
    },
    filterData() {},
    sort(item) {
      let send = {
        subscription_id: item.subscription_id,
        order: item.order,
        subscriptions: this.table.items,
      };

      this.$store
        .dispatch(`eventSubscribtion/updateSubscriptionOrder`, send)
        .then((res) => {
          if (this.$route.name == "sortEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "sortEvents2") {
            this.getDataFromApi2();
          }
        });
    },
    sortUp(item, index) {
      this.loadClicked = true;
      let send = {
        id: item.subscription_id,
        subscription_id: this.table.items[index - 1].subscription_id,
      };

      this.$store
        .dispatch(`eventSubscribtion/updateSubscriptionOrder2`, send)
        .then((res) => {
          if (this.$route.name == "sortEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "sortEvents2") {
            this.getDataFromApi2();
          }
        });
    },
    sortDown(item, index) {
      this.loadClicked = true;
      let send = {
        id: item.subscription_id,
        subscription_id: this.table.items[index + 1].subscription_id,
      };

      this.$store
        .dispatch(`eventSubscribtion/updateSubscriptionOrder2`, send)
        .then((res) => {
          if (this.$route.name == "sortEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "sortEvents2") {
            this.getDataFromApi2();
          }
        });
    },
    goToLink(item) {
      this.itemLinkMap = item
      if (item.link_map) window.open(item.link_map);
      else {
        this.note_delivary = null;
        this.$validator.reset();
        this.linkMapModal = true;
      }
    },
    updateLink(item) {
      this.itemLinkMap = item
        this.link_map = item.link_map;
        this.$validator.reset();
        this.linkMapModal = true;
    },
    goTo(routeName, item) {
      console.log(routeName);
      localStorage.setItem("event_branch_id", this.$route.params.id);
      localStorage.setItem("event_period_id", this.$route.params.id2);
      this.$router.push({
        name: routeName,
        params: { id: item.subscription_id, id2: item.subscription_detail_id },
      });
    },
    goMap(title, lat, lng) {
      this.markers = [];
      setTimeout(() => {
        this.showMap = true;
        this.markers.push({
          title: title,
          latitude: lat,
          longitude: lng,
        });
        this.$refs.AddGoogleMap.initMap();
      }, 100);
    },
    loadPDF() {
      localStorage.setItem("filterReport", JSON.stringify(this.filters));
      window.open(`/reports/${this.config.crudApi}`);
    },
    clearFilters() {
      for (let key in this.filters) {
        this.filters[key] = null;
      }
      if (this.$route.name == "sortEvents") {
        this.getDataFromApi();
      } else if (this.$route.name == "sortEvents2") {
        this.getDataFromApi2();
      }
    },
    clearData() {
      this.formData = {
        id: null,
        sort: null,
      };
      this.$validator.reset();
    },
    deleteItemConfirm() {
      this.deleteData(
        `${this.config.crudApi}/removeData`,
        this.formData.id
      ).then(() => {
        if (this.$route.name == "sortEvents") {
          this.getDataFromApi();
        } else if (this.$route.name == "sortEvents2") {
          this.getDataFromApi2();
        }
        this.dialogDelete = false;
      });
    },
    updateStatus(item) {
      item.isLoading = true;
      this.updateStatusData(`${this.config.crudApi}/updateStatus`, item.id)
        .then(() => {
          if (this.$route.name == "sortEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "sortEvents2") {
            this.getDataFromApi2();
          }
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
    closeModal() {
      this.formData.id = 0;
      this.dialog = false;
      this.$validator.reset();
    },
    save() {
      this.$validator.validateAll("addEditValidation").then((valid) => {
        if (!valid) return this.notifyCustomError("Error", "ValidationsErrors");
        this.saveUpdateData(
          `${this.config.crudApi}/saveData`,
          `${this.config.crudApi}/updateData`,
          this.formData
        ).then(() => {
          if (this.$route.name == "sortEvents") {
            this.getDataFromApi();
          } else if (this.$route.name == "sortEvents2") {
            this.getDataFromApi2();
          }
          this.closeModal();
        });
      });
    },
    getDataFromApi() {
      let options = {
        page: this.page,
        branch_id: this.$route.params.id,
        period_id: this.$route.params.id2,
        date: localStorage.getItem("event_date"),
      };
      this.table.loading = true;
      this.getFrontEndData(`${this.config.crudApi}/getData`, options)
        .then((res) => {
          res.subscriptions.map(x=>{
            x.order= '';
            return x;
          })
          this.table.items = res.subscriptions;
          this.details = {
            count_subscription: res.count_subscription,
            deliver: res.deliver,
            not_deliver: res.not_deliver,
            deliver_btn: res.deliver_btn
          };
          this.loadClicked = false;
          // this.table.length = Math.ceil(res.pagination.total / res.pagination.per_page);
        })
        .finally(() => {
          this.table.loading = false;
          this.loadClicked = false;
        });
    },
    getDataFromApi2() {
      let options = {
        page: this.page,
        period_id: this.$route.params.id2,
        city_id: this.$route.params.id,
        date: localStorage.getItem("event_date"),
      };
      this.table.loading = true;
      this.getFrontEndData(`${this.config.crudApi}/getData2`, options)
        .then((res) => {
          res.subscriptions.map(x=>{
            x.order= '';
            return x;
          })

           this.details = {
            count_subscription: res.count_subscription,
            deliver: res.deliver,
            not_deliver: res.not_deliver,
             deliver_btn: res.deliver_btn
          };
          this.table.items = res.subscriptions;

          this.loadClicked = false;
        })
        .finally(() => {
          this.table.loading = false;
          this.loadClicked = false;
        });
    },
    // changePage(page) {
    //   this.page= page;
    //   this.getDataFromApi();
    // },
  },
  mounted() {
    let me = this;
    window.addEventListener("keydown", function (e) {
      if (me) {
        if (e.code == "ArrowRight" && me.galaryModal == true) {
          if (me.galaryIndex < me.galaryImages.length - 1)
            me.galaryIndex = me.galaryIndex + 1;
        } else if (e.code == "ArrowLeft" && me.galaryModal == true) {
          if (me.galaryIndex > 0) me.galaryIndex = me.galaryIndex - 1;
        } else if (e.code == "Esc" && me.galaryModal == true) {
          me.galaryModal = false;
          me.galaryIndex = 0;
        }
      }
    });

    if (localStorage.getItem("branchesList")) {
      this.branchesList = JSON.parse(localStorage.getItem("branchesList"));
    }
    if (this.$route.name == "sortEvents") {
      this.getDataFromApi();
    } else if (this.$route.name == "sortEvents2") {
      this.getDataFromApi2();
    }
  },
};
</script>


<style>
.checkbox-marked .v-icon.v-icon {
  color: green !important;
}
.checkbox-unmarked .v-icon.v-icon {
  color: red !important;
}
</style>